import webService from '../../../../service/webService';
export const GET_DRAWS_LIST = 'GET_DRAWS_LIST';
export const GET_DRAW_HISTORY_LIST = "GET_DRAW_HISTORY_LIST";


export function getDrawDetails(id) {
    return webService.get(`v1/draw/public/${id}`);
}
export function getOrganizationDetail(slug) {
    return webService.get(`v1/organisation/public/url/${slug}`);
}

export function getDrawList(page, limit,drawType, searchText, slug) {
    return (dispatch) =>
            webService.get(`v1/draw/public?page=${page}&limit=${limit}&sortBy=id&sortDirection=asc&drawType=${drawType}&search=${searchText}&organization=${slug}`).then((response) => {
            if (response.success) {
                dispatch({
                    type: GET_DRAWS_LIST,
                    payload: {data: response.data.data, totalRecord: response.data.total}
                })
            }else {
                dispatch({
                    type: GET_DRAWS_LIST,
                    payload: {data: null, totalRecord: 0}
                })
            }
            return response;
        }
        );
}

export function getDrawHistoryList(page, limit,drawType) {
    return (dispatch) =>
        webService.get(`v1/user/ticketHistory?page=${page}&limit=${limit}&sortBy=id&sortDirection=asc&drawType=${drawType}`).then((response) => {
            if (response.success) {
                dispatch({
                    type: GET_DRAW_HISTORY_LIST,
                    payload: {historyList: response.data.data, totalHistoryRecord: response.data.total}
                })
            }else {
                dispatch({
                    type: GET_DRAW_HISTORY_LIST,
                    payload: {historyList: null, totalHistoryRecord: 0}
                })
            }
            return response;
        }
        );
}

export function getPaymentIntent(id) {
    return (dispatch) =>
        webService.get(`v1/draw/${id}/purchase`).then((response) => {
            return response;
        }
    );
}

// export const GET_FEATURED_CONTESTS = 'GET FEATURED CONTESTS';
// export function getFeaturedContests() {
//     return (dispatch) =>
//         apiService.getFeaturedContests().then((response) => {
//             if (response.success) {
//                 dispatch({
//                     type: GET_FEATURED_CONTESTS,
//                     payload: response.data.featured_contests
//                 })
//             }
//         }
//         );
// }

// export const GET_TESTIMONIALS = 'GET TESTIMONIALS';
// export function getTestimonials() {
//     return (dispatch) =>
//         apiService.getTestimonials().then((response) => {
//             if (response.success) {
//                 dispatch({
//                     type: GET_TESTIMONIALS,
//                     payload: response.data
//                 })
//             }
//         }
//         );
// }

// export const GET_STREAM = 'GET Stream';
// export function getStream() {
//     return (dispatch) =>
//         apiService.getStream().then((response) => {
//             if (response.success) {
//                 dispatch({
//                     type: GET_STREAM,
//                     payload: response.data
//                 })
//             }
//         }
//         );
// }

