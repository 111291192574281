/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import {Route} from "react-router-dom";


// export default function BreadCrumbs(props) {
//   const params = useParams();
//   console.log('params', props);

//   const Breadcrumbs = () => <Route path="*" render={props => {
//     let parts = props.location.pathname.split("/");
//     const place = parts[parts.length-1];
//     parts = parts.slice(1, parts.length-1);
//     return <p>{parts.map(crumb)}/{place}</p>}} />

//   return (
//     <Breadcrumb>
//       <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
//       <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
//         Library
//       </Breadcrumb.Item>
//       <Breadcrumb.Item active>Data</Breadcrumb.Item>
//     </Breadcrumb>
//   );
// }
const BreadCrumbs = () => <Route path="*" render={props => {
  const { pathname } = props.location;
  let parts = props.location.pathname.split("/");
  const place = parts[parts.length-1];
  parts = parts.slice(1, parts.length-1);
  
  return <Breadcrumb>
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item href={pathname}>{
        parts.map(crumb => {
          return crumb
        })
      }
      {place.replace("-", " ")}
      </Breadcrumb.Item></Breadcrumb>}} />

  export default BreadCrumbs;