import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux'; 
import AuthWrapper from '../Auth/AuthWrapper/AuthWrapper';

import styles from './Profile.module.scss';
import {Form, Button } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";
import * as Actions from './store/actions';
import {getLogedinUser} from '../../store/actions/core/app.actions';
import withReducer from "../../store/withReducer";
import reducer from "./store/reducers";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ProfileEdit = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const [user, setUser] = useState();
    const userData = useSelector(({Profile}) => {
        return Profile.profile.user
    })
    useEffect(() => {
        dispatch(Actions.getUser(params.id));
    },[params.id]);

    useEffect(() => {
        if(userData) {
            setUser(userData);
        }
    },[userData]);
    

    const initialValues = {
        firstName:  user && user.firstName || "",
        lastName:  user && user.lastName || "",
        confirmpassword:  user || "",
        mobile:  user && user.mobile || "",
        email:  user && user.email || "",
        password:  user && user.password || "",
        termCondition: ""
    };

    const LoginSchema = Yup.object().shape({
        firstName: Yup.string().required("Please enter first name."),
        lastName: Yup.string().required("Please enter last name."),
        mobile: Yup.string().required("Please enter mobile number."),
        email: Yup.string().required("Please enter email id.")
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
          dispatch(Actions.updateUser(values)).then(res => {
            if(res.success) {
                dispatch(getLogedinUser()).then(res => {
                    if(res.success) {
                        props.history.push('/')
                    }
                })
                
            }
          })
          
        },
    });

    return (
        <AuthWrapper>
            <div className={styles.content}>
                <h4 className={styles.userName}>{user && user.firstName} {user && user.lastName} Profile</h4> 
                <div>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group>
                            <Form.Control 
                                name="firstName" 
                                type="text" 
                                placeholder="First Name" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                            />
                            {formik.errors.firstName  && formik.touched.firstName  && 
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.firstName}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Control 
                                name="lastName" 
                                type="text" 
                                placeholder="Last Name" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                            />
                            {formik.errors.lastName  && formik.touched.lastName &&
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.lastName}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                        <Form.Group>
                            {/* <Form.Control 
                                name="mobile" 
                                type="text" 
                                placeholder="Mobile Number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobile}
                            /> */}
                            <PhoneInput
                                name="mobile"
                                country={'us'}
                                value={formik.values.mobile}
                                onChange={phone => {
                                    formik.setFieldValue("mobile", phone);
                                }}
                            />
                            {formik.errors.mobile  && formik.touched.mobile &&
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.mobile}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Control 
                                name="email" 
                                type="text" 
                                placeholder="Email Id" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.errors.email  && formik.touched.email &&
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.email}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                        {/* <Form.Group>
                            <Form.Control 
                                as="select" 
                                name="gender"
                                placeholder="Gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.gender}
                                defaultValue={formik.values.gender}
                            >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </Form.Control>
                            {formik.errors.gender  && formik.touched.gender &&
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.gender}
                                </Form.Control.Feedback>
                            }
                        </Form.Group> */}
                        
                        <Button variant="primary" className="btn-gradient btn-rounded mt-5" type="submit" block size="lg">
                            Update
                        </Button>
                    </Form>
                </div>
            </div>
        </AuthWrapper>
    )
}

export default withReducer("Profile", reducer)(ProfileEdit);