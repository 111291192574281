import React from "react";
import {Link} from 'react-router-dom';

import logoWithoutText from "../../../assets/images/logo-without-text.svg";
import styles from './AuthWrapper.module.scss';

const AuthWrapper = (props) => {
    const {children}  = props;
    return <div className={`${styles.authWrapper}`}>
        <div className={styles.loginWrapper}>
            {/* <img src={spiralImage} alt="spiral image"/> */}
            <div className={styles.topImage}></div>
            <div className={styles.logoContainer}>
                <Link to="/">
                
                    <img src={logoWithoutText} className={styles.brandImage} alt="Raffle Logo"/>
                    <span className={styles.brandName}>Raffle On</span>
                </Link>
                {/* <h1>Raffle On</h1> */}
            </div> 
            {children}
        </div>
        <div>
            2021 Raffle On. All Rights Reserved.
        </div>
    </div>
}

export default AuthWrapper;