import React from 'react';
import Container from 'react-bootstrap/Container';
import Banner from './components/Banner/Banner.js';
import Header from '../../components/Header/Header';
import RaffelManagement from './components/RaffelManagement/RaffelManagement';
import FundRaising from './components/FundRaising/FundRaising';
import PriceBox from './components/PriceBox/PriceBox';
import Footer from '../../components/Footer/Footer';
import styles from './LandingPage.module.scss';
const LandingPage = (props) => {
    return <div className={styles.wrapper}>
        <Header showMenu={true}/>
        <section>
            <Container fluid>
                <Banner />
                <RaffelManagement/>
                <FundRaising />
                <PriceBox />
            </Container>
        </section>
        <Footer/>
    </div>
}

export default LandingPage;