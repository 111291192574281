import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BreadCrumbs from '../../components/Breadcrumb/Breadcrumb';


import styles from './About.module.scss';


import aboutImage from '../../assets/images/about.svg';

const About = (props) => {
    
    const aboutContent = [
        {
            title:"Do more raffles, in faster time :",
            desc : "Scale up your raffle sales without increasing the effort. Simply create and promote your raffle online and watch the sales roll in." 
        },
        {
            title:"Automated QR Codes for quick click to sales page :",
            desc : "Simply create a new draw and receive the QR Codes with links to your draw online and in your email inbox. Print them off and place them around the club." 
        },
        {
            title:"Covid-Safe Raffles :",
            desc : "A fully hands-off, covid-safe raffle model. Anytime, anywhere, any conditions raffle platform." 
        }
    ]
    return <div className={`${styles.wrapper}`}>
        <Header showMenu={true}/>
        <section className={styles.section}>
            <Container fluid>
            <div>
                <Card className="h-100">
                    <BreadCrumbs />
                    <Card.Body>
                        <div className={styles.cardHeader}>
                            <Row>
                                <Col md={12}>
                                    <h2 className={styles.heading}>No need to walk around selling raffle tickets anymore</h2>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md={6}>
                                <div className={styles.aboutImage}>
                                    <img src={aboutImage} className="img-fluid" alt="about image"/>
                                </div>
                            </Col>
                            <Col md={6}>
                                {aboutContent.map((item, index) => {
                                    return <>
                                            <h5 className={styles.head}>{item.title}</h5>
                                            <p className={styles.description}>{item.desc}</p>
                                    </>
                                })}
                                {/* <div>
                                    <h5 className={styles.head}>Do more raffles, in faster time :</h5>
                                    <p className={styles.description}>Scale up your raffle sales without increasing the effort. 
                                        Simply create and promote your raffle online and watch the sales roll in.
                                    </p>
                                    <h5>Automated QR Codes for quick click to sales page :</h5>
                                    <p>
                                        Simply create a new draw and receive the QR Codes with links to your 
                                        draw online and in your email inbox. Print them off and place them 
                                        around the club.
                                    </p>
                                    <h5>Covid-Safe Raffles :</h5>
                                    <p>
                                        A fully hands-off, covid-safe raffle model. Anytime, anywhere, any conditions raffle platform.
                                    </p>
                                </div> */}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
            </Container>
        </section>
        <Footer/>
    </div>
}

export default About;