import React from "react";
import VerificationForm from './VerificationForm';
import AuthWrapper from '../AuthWrapper/AuthWrapper';

const VerificationNumber = (props) => {
    return (
        <AuthWrapper>
            <div>
                <h2>Email Verification</h2>
                <p className="text-left">We have sent verification code to your registered Email Address.</p>
                
            </div>
            <VerificationForm />
        </AuthWrapper>
    )
}

export default VerificationNumber;