import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import {Link} from 'react-router-dom';
import {Form, Button, InputGroup, Alert} from 'react-bootstrap';
import { useFormik } from "formik";

import * as Yup from "yup";
import * as Actions from '../../../store/actions/index';

import styles from './LoginPage.module.scss';

const LoginForm = (props) => {

    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(null);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const initialValues = {
        email: "",
        password: "",
        remember: false
    };
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
          // .email("Wrong email format")
          .min(3, "Minimum 3 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Email is required"),
        password: Yup.string()
          .min(3, "Minimum 3 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Password is required")
    });

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            
          dispatch(Actions.submitLogin(values)).then(res => {
            // eslint-disable-next-line 
            if(res.codes == 200 ) {
                window.localStorage.setItem("token", res.data.token)
                window.localStorage.setItem("is_login", true)
                dispatch(Actions.getLogedinUser()).then(res => {
                    window.localStorage.setItem('login_user', JSON.stringify(res.data));
                    props.history.push('/');
                });
                
            }
            // eslint-disable-next-line 
            if(res.codes == 404) {
                console.log('error', res)
                dispatch(Actions.showMessage({type: "error", message: res.message}))
                setAlert({
                    variant: "primary",
                    message: res.message
                })
            }
          });
          
        },
    });
    return <div>
        <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <Form.Group>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="bi bi-person"></i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control 
                        autoComplete="email"
                        name="email" 
                        type="email" 
                        placeholder="Username" 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </InputGroup>
                {formik.errors.email  && formik.touched.email &&
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.email}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text><i className="bi bi-lock"></i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control 
                        name="password" 
                        type={showPassword ? "text":"password"} 
                        placeholder="Password" 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="new-password"
                    />
                   { !showPassword ? <i className={`bi bi-eye ${styles.eyeIcon}`} onClick={toggleShowPassword}></i>
                     : <i className={`bi bi-eye-slash ${styles.eyeIcon}`} onClick={toggleShowPassword}></i>}
                </InputGroup>
                {formik.errors.password && formik.touched.password && 
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.password}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                <div className="d-flex justify-content-between">
                    <Form.Check name="remember" className="d-flex align-items-center" type="checkbox" label="Remember me" onChange={formik.handleChange} checked={formik.values.remember}/>
                    <Link className={styles.forgotLink} to="/forgotpassword">Forgot Password?</Link>
                </div>
            </Form.Group>
            <Button variant="primary" className="btn-gradient btn-rounded button-shadow mt-4" type="submit" block size="lg">
                Submit
            </Button>
            {alert != null && <Alert variant={alert.variant} onClose={() => setAlert(null)} dismissible className="mt-2">
                {alert.message}
            </Alert>}
        </Form>
    </div>
}

export default LoginForm;