/* eslint-disable */

import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// // pages for this product
// import LandingPage from "views/LandingPage/LandingPage.js";

// import RegisterPage from "views/Auth/Register/RegisterPage.js";

import LoginPage from "./views/Auth/LoginPage/LoginPage.js";
import SignUp from "./views/Auth/SignUp/SignUp.js";
import ChangePassword from "./views/Auth/ChangePassword/ChangePassword";
import VerificationNumber from "./views/Auth/VerificationNumber/VerificationNumber";
import ForgotPassword from "./views/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/Auth/ResetPassword/ResetPassword";
import LandingPage from "./views/LandingPage/LandingPage";
import Draws from "./views/Draws/Draws";
import Organization from "./views/Organization/Draws";
import About from "./views/About/About";
import Profile from "./views/Profile/Profile";
import ProfileEdit from "./views/Profile/ProfileEdit";
import DrawsHistory from "./views/Draws/DrawsHistory";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
// import ForgotPassword from "views/Auth/ForgotPassword/ForgotPassword.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./protected.route";
// import { ProtectedRoute1 } from "./protected.route1";
import { useDispatch, useSelector } from "react-redux";
import Faq from "./views/FAQ/Faq.js";

// import Message from "components/Message/Message";

// import OrganizationLayout from "./layouts/organization/OrganizationLayout";

// import Feedback from "simple-screenshot-feedback"

const AppRouter = () => {
  // const previousPath = useSelector(({ core }) => core.appStore.previousPath);

  // function handleSubmitError(Error){
  //     console.log("call",Error)
  // }

  window.addEventListener("resize", () => {
    setCustomViewportHeight();
  });

  const setCustomViewportHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  return (
    <>
      <ScrollToTop />
      {/* <Message />   */}
      {/* <ToastContainer /> */}
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/login" render={(props) => <LoginPage {...props} />} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/resetpassword" component={ResetPassword} />
          {/* <Route path="/changepassword" component={ChangePassword}/> */}
          <ProtectedRoute path="/changepassword" component={ChangePassword} />
          <Route path="/verify" component={VerificationNumber} />
          {/* <Route path="/landing-page" component={LandingPage}/> */}
          {/* <Route path="/draws" component={Draws}/> */}
          <ProtectedRoute path="/draws/:id" component={Draws} />
          <ProtectedRoute path="/draws" component={Draws} />

          <ProtectedRoute path="/draws-history" component={DrawsHistory} />
          <ProtectedRoute path="/club/:slug/:id" component={Organization} />
          <ProtectedRoute path="/club/:slug" component={Organization} />
          <ProtectedRoute path="/profile/:id" component={ProfileEdit} />
          <ProtectedRoute path="/profile" component={Profile} />

          {/* <Route path="/draws-history" component={DrawsHistory}/> */}
          <Route path="/about" component={About} />
          <Route path="/faq" component={Faq} />

          {/* <Route path="/profile/:id" render={(props) => <ProfileEdit {...props}/> }/>
                    <Route path="/profile" render={(props) => <Profile {...props}/>}/> */}
          {/* <Redirect to="/landing-page"/> */}
          <Route path="/" component={LandingPage} />

          <Redirect from="/landing-page" to="/" />
          <Redirect from="*" to="/" />

          {/* <Route path="/password/reset" component={ForgotPassword} />

                    <Route path="/register" component={RegisterPage} />

                    <ProtectedRoute1 exact path="/home" component={LandingPage} />
                    <ProtectedRoute1 exact path="/" component={LandingPage} /> */}
        </Switch>
      </Suspense>
    </>
  );
};

export default AppRouter;
