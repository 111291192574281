import {combineReducers} from 'redux';
 import core from './core';
// import auth from 'app/auth/store/reducers';

const createReducer = (asyncReducers) =>
    combineReducers({
        core,
        ...asyncReducers
    });

export default createReducer;
