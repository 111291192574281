import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import raffelManagement from '../../../../assets/images/raffel-management.png'; 
import styles from './RaffelManagement.module.scss';

const RaffelManagement = (props) => {
    const list = [
        "Supply your own prizes",
        "Set your own ticket price and prize allocation",
        "Manage your own landing page with rich media content and custom URL.",
        "Full reporting and export options on each draw",
        "Invite and manage raffle administrators.",
        "Manually manage your own draws. your own way.",
    ]
    return (
        <section id="raffelmanagement">
            <h2 className={styles.sectionHeading}>Raffle Management</h2>
            <Row>
                <Col md={6}>
                    <img src={raffelManagement} className="img-fluid p-5" alt="Raffle Management"/>
                </Col>
                <Col md={6}>
                    <ul className={styles.list}>
                        {list.map((item, index) => {
                            return <li className="d-flex" key={index}>
                                <i className="bi bi-check-circle-fill"></i>
                                {item}
                            </li>
                        })}
                    </ul>
                </Col>
            </Row>
        </section>
    )
}

export default RaffelManagement;