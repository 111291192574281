/* eslint-disable */
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import Provider from 'react-redux/es/components/Provider';
// import WebSocketProvider from './service/webSocket';
import store from './store';
import history from './history';
import AppRouter from './AppRouter';
import './styles/styles.scss';

class App extends React.Component {

  render() {
    return (
      <Provider store={store}>
          <Router  history={history}>
            <AppRouter />
          </Router>
      </Provider>
    )
  }
}
ReactDOM.render(<App />, document.getElementById('root'));
