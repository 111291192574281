import React from "react";
import {Link} from 'react-router-dom';
import SignUpForm from './SignUpForm';
import AuthWrapper from '../AuthWrapper/AuthWrapper';

import styles from './SignUp.module.scss';

const SignUp = (props) => {
    return (
        <AuthWrapper>
            <div>
                <h2>Already have an account? <Link className={styles.link} to="/login">Sign in</Link></h2>
                <p>Signup to create your Raffle On account.</p>
                
                <SignUpForm />
            </div>
        </AuthWrapper>
    )
}

export default SignUp;