import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ReactCodeInput from 'react-verification-code-input';


import * as Actions from '../../../store/actions/index';
import styles from './VerificationNumber.module.scss';

const VerificationForm  = (props) => {
    const dispatch = useDispatch();
    const [mobile, setMobile] = useState(null)
    const [timer, setTimer] = useState(60);
    const [otp, setOtp] = useState(null);
    const [resendOtp, setResendOtp] = useState(false);
    useEffect(() => {
        if(timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1)
            },1000)
        }
        if(timer == 0) {
            setResendOtp(true);
        }
    }, [timer]);
    const startTimer = () => {
        setTimer(60);
    }
    // eslint-disable-next-line 
    useEffect(() => {
        if(mobile == null) {
            let mobile = window.localStorage.getItem('mobileNumber');
            setMobile(mobile);
        }
    })
    const handleComplete = (value) => {
        
        setOtp(value);
    }

    const handleChange = () => {
        // dispatch(Actions.submitLogin(true));
        dispatch(Actions.otpVerify(otp)).then(res => {
            if(res.success) {
                dispatch(Actions.getLogedinUser()).then(res => {
                    window.localStorage.setItem('login_user', JSON.stringify(res.data));
                    props.history.push('/');
                });
                // props.history.push('/');
            }else {
                setResendOtp(true);
            }
        })
    }
    const handleResendOtp = () => {
        let obj = JSON.parse(window.localStorage.getItem("resendOtp"));
        if(obj) {
            dispatch(Actions.sendOtp({userName: obj.mobile, password: obj.password})).then(res => {
                setResendOtp(false);
                startTimer()    
            })
        }   
    }
    return <>
        <h2 className={styles.number}> {mobile}</h2>
        <ReactCodeInput 
            fieldWidth={40}
            fieldHeight={40}
            onComplete={handleComplete}
            className="py-3"
        />
        {timer > 0 && <div className={styles.timer}>
            {`you can resend the OTP after ${timer} sec.`}
        </div>}
        {resendOtp && <div className={styles.resend}>
           Not yet received? <a onClick={handleResendOtp} className={styles.resendButton}>Re-send</a>
        </div>}
        <div className="form-group">
            <Button variant="primary" className="btn-gradient btn-rounded button-shadow mt-4" block size="lg" onClick={handleChange}>
                Confirm OTP
            </Button>
        </div>
    </>
}

export default withRouter(VerificationForm);