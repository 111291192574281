import React, {useState} from "react";
import {Link} from 'react-router-dom';
import { useFormik } from "formik";
import {useDispatch} from 'react-redux';
import * as Yup from "yup";
import * as Actions from '../../../store/actions/index';
import {Form, Button, InputGroup, Alert} from 'react-bootstrap';
import AuthWrapper from '../AuthWrapper/AuthWrapper';

import styles from '../LoginPage/LoginPage.module.scss';


const ForgotPassword = (props) => {
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(null);

    const initialValues = {
        email: ""
    };
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
          // .email("Wrong email format")
          .min(3, "Minimum 3 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Email is required"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
          dispatch(Actions.forgotPassword(values)).then(res => {
            // eslint-disable-next-line 
            if(res.codes == 200 ) {
                setAlert({
                    variant: "success",
                    message: res.message
                });
                // props.history.push('/resetpassword');    
            }
            // eslint-disable-next-line 
            else {
                setAlert({
                    variant: "danger",
                    message: res.message
                })
            }
          });
          
        },
    });

    return (
        <AuthWrapper>
            <div>
                <h2>Forgot Password</h2>
                
                <div>
                    <Form onSubmit={formik.handleSubmit} autoComplete="off">
                        <Form.Group>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="bi bi-person"></i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control 
                                    autoComplete="email"
                                    name="email" 
                                    type="email" 
                                    placeholder="Email" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </InputGroup>
                            {formik.errors.email  && formik.touched.email &&
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.email}
                                </Form.Control.Feedback>
                            }
                        </Form.Group>
                        
                        
                        <Button variant="primary" className="btn-gradient btn-rounded button-shadow mt-4" type="submit" block size="lg">
                            Send
                        </Button>
                        {alert != null && <Alert variant={alert.variant} onClose={() => setAlert(null)} dismissible className="mt-2">
                            {alert.message}
                        </Alert>}
                    </Form>
                </div>
            </div>
        </AuthWrapper>
    )
}

export default ForgotPassword;