// import webApiService from '../../../service/webApiService'
// import { getLoginUserInfo } from '../../../functions/getLoginData'
import webService from '../../../service/webService';

export const IS_LOGEDIN_USER = 'IS LOGEDIN USER';


export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin(data) {
  return (dispatch) => 
    webService.post('v1/auth/login',data).then((response) => {
        if(response.codes == 404) {
          // return dispatch({
          //   type: LOGIN_ERROR,
          //   payload: response.message
          // });
          return response;
        }
        dispatch({
          type: IS_LOGEDIN_USER,
          payload: true
        })
        return response
      }
    ).catch(error => {
      return dispatch({
        type: LOGIN_ERROR,
        payload: error
      });
    });
}

export const USER_LOGOUT = 'USER LOG OUT';

export function logout(id) {
  return (dispatch) => {
    // webApiService.logout(id).then((response) => {
    //     if (response.success) {
    //       return {
    //         type: USER_LOGOUT,
    //         payload: response
    //       }
    //     }
    //   }
    // )
    }
}


export const GET_LOGEDIN_USER = 'GET LOGEDIN USER';

export function getLogedinUser() {
  return (dispatch) => webService.get('v1/auth/profile').then((response) => {
        if(response.codes == 200) {
          window.localStorage.setItem('login_user', JSON.stringify(response.data))
        }
        return response
      }
    ).catch(error => {
      console.log('error', error);
      return error;
    });
}


export function changePassword(data) {
  return (dispatch) => webService.post('v1/auth/changePassword', data).then((response) => {
        return response
      }
    ).catch(error => {
      console.log('error', error)
    });
}
export function sendOtp(data) {
  return (dispatch) => webService.get('v1/auth/otp/resend', data).then((response) => {
        return response
      }
    ).catch(error => {
      console.log('error', error)
    });
}
export function otpVerify(otp) {
  return (dispatch) => webService.post('v1/auth/otp/verify', {otp: otp}).then((response) => {
        return response
      }
    ).catch(error => {
      console.log('error', error)
    });
}

export const STORE_PREVIOUS_PATH = 'STORE_PREVIOUS_PATH';

export function storePreviousPath(path) {
  return {
    type: STORE_PREVIOUS_PATH,
    payload: path
  }
}



export function userRegister(data) {
  return (dispatch) => {
    return webService.post('v1/auth/register',data);
  }
}

export function forgotPassword(data) {
  return (dispatch) => {
    return webService.get(`v1/auth/forgotPassword?username=${data.email}`);
  }
}

export function resetPassword(data) {
  return (dispatch) => {
    return webService.post(`v1/auth/ResetPassword`, data);
  }
}

export const ORG_LIST = 'ORG_LIST';
export function organizationSearch(page, limit, searchText) {
  return (dispatch) => {
    webService.get(`v1/organisation/public?page=${page}&limit=${limit}&sortBy=id&sortDirection=asc&search=${searchText}`).then(res => {
      console.log('org list', res);
      if(res.codes == 200 ) {
        dispatch({type: ORG_LIST, payload: res.data});
      }
      return  res;
    }).catch(err => {
      console.log('org list error', err);
      return err;
    })
  }
}

