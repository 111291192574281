import React, {useState} from 'react';
import { withRouter} from 'react-router-dom';
import {Form, Button, InputGroup} from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from './ChangePassword.module.scss';
import { useDispatch } from 'react-redux';
import * as Actions from '../../../store/actions';
import PasswordField from '../PasswordField';

const ChangePasswordForm = (props) => {
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const initialValues = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    };
    const LoginSchema = Yup.object().shape({
        oldPassword: Yup.string()
          .min(3, "Minimum 3 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Password is required"),
        newPassword: Yup.string()
          .min(3, "Minimum 3 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Password is required"),
        confirmPassword: Yup.string().when("New password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("New password")],
              "New password not match"
            )
        }).required("Please enter confirm  password."),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            let data = values;
            delete data.confirmPassword;
            dispatch(Actions.changePassword(data)).then(res => {
                if(res.codes === 200) {
                    props.history.push('/');
                }
            });
            
        },
    });
    return <div>
        <Form onSubmit={formik.handleSubmit} autoComplete="off">
            <Form.Group>
               
                <PasswordField 
                    name="oldPassword"
                    type={showPassword ? "text" : "password"}
                    placeholder="Old Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="password"
                    showlock={ true}
                />
                
                {formik.errors.oldPassword && formik.touched.oldPassword && 
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.oldPassword}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
               
                <PasswordField 
                    name="newPassword"
                    type={showPassword ? "text" : "password"}
                    placeholder="New Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="password"
                    showlock={ true}
                />
                
                {formik.errors.newPassword && formik.touched.newPassword && 
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.newPassword}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                
                <PasswordField 
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="password"
                    showlock={ true }
                />
                   
                
                {formik.errors.confirmPassword && formik.touched.confirmPassword && 
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.confirmPassword}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Button variant="primary" className="btn-gradient btn-rounded button-shadow mt-5" type="submit" block size="lg">
                Change
            </Button>
        </Form>
    </div>
}

export default withRouter(ChangePasswordForm);