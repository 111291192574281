import React from 'react';
import Card from 'react-bootstrap/Card';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import styles from './DrawCard.module.scss';
import moment from 'moment';

const DrawCard = (props) => {
    const history = useHistory();
    let card = props.data;
    const { handleShow } = props;

    const getDateInFormat = (date, format) => {
        return moment(date).format(format);
    }

    const handleBook = () => {
        history.push(`/draws/${card.id}`);
    }

    let now = +new Date();
    let startDate = +moment.utc(card.startDate, "YYYY-MM-DD HH:mm:ss").toDate();

    
    return (<div className={styles.wrapper} key={props.key}>
        <Card className={styles.card}>
            <Card.Img variant="top" src={card.drawImage} className={styles.cardImg} />
            <Card.Body className={styles.cardBody}>
                <p className={styles.name}>{card.drawName}</p>
                {card.organisationName ? <Link to={`/club/${card.organisationName}`} className={styles.orgName}>{card.organisationName}</Link> : ''}
                <Card.Text className={styles.date}>
                    Date {getDateInFormat(card.startDate, "DD MMM YYYY")} to  {getDateInFormat(card.endDate, "DD MMM YYYY")}
                </Card.Text>

                <div className={"d-flex justify-content-between align-items-center"}>
                    <h3 className={styles.price}>${card.price} each</h3>
                    {now > startDate &&
                        <>
                            {card.availableTickets && card.availableTickets > 0 ?
                                <Button onClick={handleBook} variant="dark" color="primary" size="sm" className="px-1" className={styles.bookNow}>Book Now</Button>
                                :
                                <Button disabled variant="dark" color="primary" size="sm" className="px-1" className={styles.bookNow}>Sold out</Button>

                            }
                        </>
                    }
                </div>
            </Card.Body>
        </Card>
    </div>)

}

export default withRouter(DrawCard);