import React, {useState} from 'react';
import { useHistory, useLocation, withRouter} from 'react-router-dom';
import {Form, Button, InputGroup, Alert} from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from './ResetPassword.module.scss';
import { useDispatch } from 'react-redux';
import * as Actions from '../../../store/actions';
import PasswordField from '../PasswordField';

const ResetPassowrdForm = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const [showPassword, setShowPassword] = useState(false);
    const [alert, setAlert] = useState(null);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const initialValues = {
        password: "",
        confirmPassword: ""
    };
    const LoginSchema = Yup.object().shape({
        password: Yup.string()
          .min(3, "Minimum 3 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Password is required"),
        confirmPassword: Yup.string().when("New password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("New password")],
              "New password not match"
            )
        }).required("Please enter confirm  password."),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            let data = {...values, token: token};
            delete data.confirmPassword;

            dispatch(Actions.resetPassword(data)).then(res => {
                if(res.codes == 200 ) {
                    setAlert({
                        variant: "success",
                        message: res.data
                    })
                    setTimeout(() => {
                        history.push('/login'); 
                    }, 5000)   
                }
                // eslint-disable-next-line 
                else {
                    setAlert({
                        variant: "danger",
                        message: res.message
                    })
                }
            });
            
        },
    });
    if(token == null || token == "") {
        history.push('/login');
    }
    return <div>
        <Form onSubmit={formik.handleSubmit} autoComplete="off">
           
            {/* <Form.Group>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text><i class="bi bi-keyboard"></i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control 
                        autoComplete="token"
                        name="token" 
                        type="text" 
                        placeholder="Token" 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </InputGroup>
                {formik.errors.token  && formik.touched.token &&
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.token}
                    </Form.Control.Feedback>
                }
            </Form.Group> */}
            
            <Form.Group>
               
                <PasswordField 
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="New Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="password"
                    showlock={ true}
                />
                
                {formik.errors.password && formik.touched.password && 
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.password}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                
                <PasswordField 
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="password"
                    showlock={ true }
                />
                   
                
                {formik.errors.confirmPassword && formik.touched.confirmPassword && 
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.confirmPassword}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Button variant="primary" className="btn-gradient btn-rounded button-shadow mt-5" type="submit" block size="lg">
                Submit
            </Button>
            {alert != null && <Alert variant={alert.variant} onClose={() => setAlert(null)} dismissible className="mt-2">
                {alert.message}
            </Alert>}
        </Form>
    </div>
}

export default withRouter(ResetPassowrdForm);