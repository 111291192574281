import React, {useState} from 'react';
import { withRouter} from 'react-router-dom';
import {Form, Button, InputGroup} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styles from './Password.module.scss';

const PasswordField = (props) => {
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(props.showPassword);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }
    let showlock = props.showlock && true;
    return (
       <>
        {showlock ? 
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text><i className="bi bi-lock"></i></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control 
                    {...props}
                    type= {showPassword ? 'text': 'password'}
                />
                { !showPassword ? <i className={`bi bi-eye ${styles.eyeIcon}`} onClick={toggleShowPassword}></i>
                    : <i className={`bi bi-eye-slash ${styles.eyeIcon}`} onClick={toggleShowPassword}></i>}
            </InputGroup> 

            : 
                <>
                    <Form.Control 
                    {...props}
                    type= {showPassword ? 'text': 'password'}
                />
                { !showPassword ? <i className={`bi bi-eye ${styles.eyeIcon}`} onClick={toggleShowPassword}></i>
                    : <i className={`bi bi-eye-slash ${styles.eyeIcon}`} onClick={toggleShowPassword}></i>}
                </>
        }  
        </> 
    )
       
}

export default PasswordField;