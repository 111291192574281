import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import fourLine from '../../../../assets/bgImage/four-line-corner.png';
import styles from './FundRaising.module.scss';

const FundRaising = props => {

    const cards = [
        {
            title: "Meat Tray",
            description: "Sell your traditional meat tray raffle tickets, but in a modern way on RaffleOn. Replace the chore of walking around the crowd and selling the raffle tickets and sell it online. No cash handling, less Covid, make life simpler."
        },
        {
            title: "Donated or Purchased Prizes",
            description: "A great way to increase your margins on Raffles is to have your prizes donated. Engage with your community, members and sponsors to donate prizes. If you can’t achieve that when required, simply using well thought out purchased prizes is a great way to drive interest in ticket sales for your raffles."
        },
        {
            title: "Cash Prizes",
            description: "Simple, easy and quick to get up and running. Everybody loves a cash prize, so it’s easy to lead in with that as your main prize. This prize option helps you run raffles more consistently and quickly."
        },
        {
            title: "Customised Options",
            description: "This platform essentially allows the club administrator to use their imagination to shape the setup of the raffles however you like. Customise your prizes to whatever suits, no cash handling, less chance of Covid, making life simpler for administrators and fundraisers."
        }
        // ,
        // {
        //     title: "title 5",
        //     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum"
        // },
        // {
        //     title: "title 6",
        //     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum"
        // },
    ]
    return (
        <section className={styles.section} id="fundraising">
            <h2 className={styles.sectionHeading}>Fundraising Experience</h2>
            <Row>
                <Col md={10} className="m-auto">
                    <Row>
                        {cards.map((card, index) => {
                            return <Col md={4} key={index}>
                                <Card className={`${styles.card} text-center position-relative`}>
                                    <Card.Body className={styles.cardBody}>
                                        <img src={fourLine} className={styles.cardImg}/>
                                        <Card.Title className={styles.title}>{card.title}</Card.Title>
                                        <Card.Text className={styles.description}>{card.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        })}
                    </Row>
                </Col>
            </Row>
        </section>
    );
};

export default FundRaising;