import React from "react";
import {Link} from 'react-router-dom';
import LoginForm from './LoginForm';
import AuthWrapper from '../AuthWrapper/AuthWrapper';

import styles from './LoginPage.module.scss';


const LoginPage = (props) => {
    return (
        <AuthWrapper>
            <div>
                <h2>New to Raffle On? <Link className={styles.link} to="/signup">Sign Up</Link></h2>
                <p>Login to your account to access your Raffle On.</p>
                
                <LoginForm {...props}/>
            </div>
        </AuthWrapper>
    )
}

export default LoginPage;