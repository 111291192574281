/* eslint-disable */

import React from "react";
import { withRouter } from "react-router-dom";


class ScrollToTop extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidUpdate(prevProps) {

        if (this.props.location.pathname !== prevProps.location.pathname) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 50)
        }
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTop)