import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BreadCrumbs from '../../components/Breadcrumb/Breadcrumb';
import PaginationBox from './components/Pagination/Pagination';
import Filter from './components/Filter';
import withReducer from "../../store/withReducer";
import reducer from "./store/reducers";

// import ReactTable from '../../components/ReactTable/ReactTable';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import logoWithoutText from "../../assets/images/logo-without-text.svg";
import logo from '../../assets/images/logo.svg';
import close from '../../assets/images/close.svg';
import styles from './DrawsHistory.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from './store/actions'
import moment from 'moment';

const filterCheckbox = [{
    label: "All",
    value: 'all'
}, {
    label: "Most recent",
    value: "upcoming"
}, {
    label: "This month",
    value: "current"
    }];

const DrawsHistory = (props) => {
   
    const [drawType, setDrawType] = useState('all');
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [loader, setLoader] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const columns = [
        {
            text: 'Draw Name',
            dataField: 'drawName',
            sort: true,
            classes: styles.drawName
        },
        {
            text: 'Price',
            dataField: 'price',
            sort: true
        },
        {
            text: 'Start Date',
            dataField: 'startDate',
            sort: true,
            formatter: function(cellContent,row, rowIndex) {
                return moment.utc(row.startDate).format("YYYY-MM-DD HH:mm:ss").toLocaleString();
            }
        },
        {
            text: 'End Date',
            dataField: 'endDate',
            sort: true,
            formatter: function(cellContent,row, rowIndex) {
                return moment.utc(row.endDate).format("YYYY-MM-DD HH:mm:ss").toLocaleString();
            }
        },
        {
            text: 'No. of Ticket',
            dataField: 'ticketNumbers',
            sort: true
        }
        // , {
        //     text: 'Action',
        //     dataField: 'action',
        //     formatter: function(cellContent, row, rowIndex) {
        //         return <i className={`bi bi-info-square ${styles.cursor}`} onClick={() => handleShow(row)}></i>
        //     }
        // }    
    ];

    const handleClose = () => {
        setSelectedRow(null);
        setShow(false);
    };
    const handleShow = (card) => {
        setShow(true);
        setSelectedRow(card);
    };

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            { from } - { to } of { size } items
        </span>);
    
  
    const historyList = useSelector(({ DrawsStore }) => {return DrawsStore.draws.historyList;});
    
    const totalRecord = useSelector(({DrawsStore} ) => {return DrawsStore.draws.totalHistoryRecord;});
    
    useEffect(() => {
        setLoader(true);
        dispatch(Actions.getDrawHistoryList(page, limit, drawType)).then(res => {
            setLoader(false);
        })
    }, [dispatch, page, limit, drawType, searchText]);

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        totalSize: totalRecord,
        paginationTotalRenderer: customTotal,
        onSizePerPageChange: (sizePerPage, page) => {
            setLimit(sizePerPage);
        },
        onPageChange: (page, sizePerPage) => {
            setPage(page);
        },
        disablePageTitle: false,
       // A numeric array is also available. the purpose of above example is custom the text
    };
    const handleFilterChange = (name, type) => {
        if(drawType == name) {
            setDrawType('')
        }else
            setDrawType(name);
    }

    const handleSearch = (text) => {
        setSearchText(text);
    }
 
    return <div className={`${styles.wrapper}`}>
        <Header showMenu={false}/>
        <section className={styles.section}>
            <Container fluid>
            <div>
                <Card>
                    <BreadCrumbs/>
                    <Card.Body className={styles.cardBody}>
                        <div className={styles.cardHeader}>
                            <Row>
                                <Col md={4}>
                                    <h2 className={styles.heading}>Draws history</h2>
                                </Col>
                                <Col md={8}>
                                    {/* <Filter filterCheckbox={ filterCheckbox }totalRecord={totalRecord} drawType={drawType} onChange={handleFilterChange} onSearch={handleSearch}/> */}
                                </Col>
                            </Row>
                        </div>   
                        
                        {loader ? 
                            <Row>
                                <Col>
                                    <div className="loader">
                                        <div className="spinner-border text-warning" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        :
                            <>
                                {historyList != null && <Row className="my-4">
                                    <Col>
                                        <BootstrapTable wrapperClasses="table-responsive min-height" keyField='id' bootstrap4={true} data={ historyList } columns={ columns } pagination={ paginationFactory(options) } noDataIndication ={"No data found"}/>
                                    </Col>
                                </Row>}
                                {historyList == null && 
                                    <Col>
                                        <h3 className={styles.noData}>Draw History Not Found</h3>
                                    </Col>
                                }    
                            </>
                        }
                    </Card.Body>
                </Card>
            </div>
            </Container>
        </section>
        <Footer/>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
        
            <Modal.Body className={styles.modalBody}>
            <Card className={styles.modalCard}>
                <Card.Body className="p-0">
                    <div className={styles.imageBox}>
                        <img src={close} className={styles.closeIcon} onClick={() => handleClose()}/>
                        <img src={logoWithoutText} className={styles.logo}/>
                        <span className={styles.brandName}>Raffle On</span>
                    </div>
                    <code>
                        {JSON.stringify(selectedRow)}
                    </code>
                </Card.Body>
            </Card>
            </Modal.Body>
        </Modal>
    </div>
}

// export default DrawsHistory;
export default withReducer("DrawsStore", reducer)(DrawsHistory);