import React from "react";

import ResetPasswordForm from './ResetPasswordForm';
import AuthWrapper from '../AuthWrapper/AuthWrapper';


const ResetPassowrd = (props) => {
    return (
        <AuthWrapper>
            <div>
                <h2>Reset Password</h2>
                <p>You can reset your password & access your Raffle On.</p>
                
                <ResetPasswordForm />
            </div>
        </AuthWrapper>
    )
}

export default ResetPassowrd;