import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux'
import withReducer from "../../store/withReducer";
import reducer from "./store/reducers";
import AuthWrapper from '../Auth/AuthWrapper/AuthWrapper';
import styles from './Profile.module.scss';
import * as Actions from './store/actions';

const Profile = (props) => {
    const dispatch = useDispatch();
    const [user, setUser] = useState({firstName: '', lastName: '', mobile: '', email: '', gender: 'male'});
    const userData = useSelector(({Profile})=> {
        return Profile.profile.user;
    });
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        dispatch(Actions.getUser()).then(res => {

        });
    },[]);
    useEffect(() => {
        if(userData) {
            setUser({...user, ...userData});
            setLoader(false);
        }
    },[userData]);
    
    return (
        <AuthWrapper>
            <div className={styles.content}>
                <h4 className={styles.userName}>{user.firstName} {user.lastName} Profile</h4> 
                <Link to="/profile/3" className="text-center d-flex justify-content-center">Edit <i className="bi bi-pencil ml-2"></i></Link>

                {
                    loader ? 
                        <div className="loader">
                            <div className="spinner-border text-warning" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    :
                    <div>
                        <div className={styles.formGroup}>
                            <div className={styles.formControl}>{user.firstName}</div>
                        </div>
                        <div className={styles.formGroup}>
                            <div className={styles.formControl}>{user.lastName}</div>
                        </div>
                        <div className={styles.formGroup}>
                            <div className={styles.formControl}>{user.mobile}</div>
                        </div>
                        <div className={styles.formGroup}>
                            <div className={styles.formControl}>{user.email}</div>
                        </div>
                        {/* <div className={styles.formGroup}>
                            <div className={styles.formControl}>johnmaster@gmail.com</div>
                        </div> */}
                        {/* <div className={styles.formGroup}>
                            <div className={styles.formControl}>{user.gender}</div>
                        </div> */}
                    </div>
                }
                
            </div>
        </AuthWrapper>
    )
}

export default withReducer('Profile', reducer)(Profile);