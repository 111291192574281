import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Form, Button, Alert} from 'react-bootstrap';
import { useFormik } from "formik";
import {withRouter} from 'react-router-dom';
import * as Yup from "yup";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as Actions from '../../../store/actions/index';
import PasswordField from '../PasswordField';


const SignUpForm = (props) => {
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(null);
    const initialValues = {
        firstName:"",
        lastName: "",
        confirmpassword: "",
        mobile: "",
        email: "",
        password: "",
        termCondition: ""
    };
    const LoginSchema = Yup.object().shape({
        firstName: Yup.string().required("Please enter first name."),
        lastName: Yup.string().required("Please enter last name."),
        password: Yup.string()
          .min(3, "Minimum 3 symbols")
          .max(30, "Maximum 30 symbols")
          .required("Password is required"),
        mobile: Yup.string().required("Please enter mobile number."),
        email: Yup.string().required("Please enter email id."),
        confirmpassword: Yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              "password not match"
            )
          }).required("Please enter confirm  password."),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            
            let data = {...values}
            delete data.confirmpassword;
            delete data.termCondition;
          dispatch(Actions.userRegister(data)).then(res => {
              
              if (res.codes === 200) {
                    dispatch({type: Actions.IS_LOGEDIN_USER, payload: true});
                    window.localStorage.setItem('resendOtp', JSON.stringify(values));
                    window.localStorage.setItem("mobileNumber", res.data.mobileNumber)
                    window.localStorage.setItem("token", res.data.token)
                    window.localStorage.setItem("is_login", true)
                    dispatch(Actions.sendOtp({userName: values.mobile,password: values.password})).then(res => {
                        props.history.push('/verify');      
                    })
              }else {
                setAlert({
                    variant: "danger",
                    message: res.message
                })
              }
          })
          
        },
    });
    return <div>
        <Form className="reg-form" onSubmit={formik.handleSubmit}>
            <Form.Group>
                <label className="form-label">First Name</label>
                <Form.Control 
                    name="firstName" 
                    type="text" 
                    placeholder="First Name" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.firstName  && formik.touched.firstName  && 
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.firstName}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                <label className="form-label">Last Name</label>
                <Form.Control 
                    name="lastName" 
                    type="text" 
                    placeholder="Last Name" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.lastName  && formik.touched.lastName &&
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.lastName}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                <label className="form-label">Password</label>
                <PasswordField 
                    name="password" 
                    type="password" 
                    placeholder="Password" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    showlock={false}
                    showPassword={false}
                />
                {/* <Form.Control 
                    name="password" 
                    type="password" 
                    placeholder="Password" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                /> */}
                {formik.errors.password && formik.touched.password && 
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.password}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                <label className="form-label">Confirm Password</label>
                <PasswordField 
                    name="confirmpassword" 
                    type="password" 
                    placeholder="Confirm Password" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    showlock={false}
                    showPassword={false}
                />
                {/* <Form.Control 
                    name="confirmpassword" 
                    type="password" 
                    placeholder="Confirm Password" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                /> */}
                {formik.errors.confirmpassword && formik.touched.confirmpassword && 
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.confirmpassword}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                {/* <Form.Control 
                    name="mobile" 
                    type="text" 
                    placeholder="Mobile Number" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                /> */}
                <label className="form-label">Mobile</label>
                <PhoneInput
                    name="mobile"
                    country={'au'}
                    value={formik.values.mobile}
                    onChange={phone => {formik.setFieldValue("mobile", phone);}}
                />
                {formik.errors.mobile  && formik.touched.mobile &&
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.mobile}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            <Form.Group>
                <label className="form-label">Email</label>
                <Form.Control 
                    name="email" 
                    type="text" 
                    placeholder="Email Id" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.errors.email  && formik.touched.email &&
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.email}
                    </Form.Control.Feedback>
                }
            </Form.Group>
            {/* <Form.Group>
                <Form.Control 
                    as="select" 
                    name="gender"
                    placeholder="Gender"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.gender}
                >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </Form.Control>
                {formik.errors.gender  && formik.touched.gender &&
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.gender}
                    </Form.Control.Feedback>
                }
            </Form.Group> */}
            <Form.Group>
                <div className="d-flex justify-content-between">
                    {/* <Form.Check name="remember" className="d-flex align-items-center" type="checkbox" label="I hereby agree to the term and condition" onChange={formik.handleChange} checked={formik.values.termCondition}/> */}
                    <div className="form-check">
                        <input name="remember" className="form-check-input" type="checkbox" id="flexCheckIndeterminate"/>
                        <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                            I hereby agree to the <a href="#"> term and condition</a>
                        </label>
                    </div>
                </div>
            </Form.Group>
            <Button variant="primary" className="btn-gradient btn-rounded button-shadow mt-4" type="submit" block size="lg">
                Register
            </Button>
            {alert != null && <Alert variant={alert.variant} onClose={() => setAlert(null)} dismissible className="mt-2">
                {alert.message}
            </Alert>}
        </Form>
    </div>
}

export default withRouter(SignUpForm);