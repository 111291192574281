import React from "react";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        console.log('protected', JSON.parse(window.localStorage.getItem('is_login')))
        if (JSON.parse(window.localStorage.getItem('is_login'))) {
          return <Component {...props} {...rest}/>;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
