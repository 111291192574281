import webService from '../../../../service/webService';
export const GET_USER = 'GET_USER';

export function getUser(id) {
    return (dispatch) =>
            webService.get('v1/auth/profile').then((response) => {
            if (response.success) {
                dispatch({
                    type: GET_USER,
                    payload: response.data
                })
            }
            return response;
        }
        );
}
export function updateUser(data) {
    return (dispatch) =>
            webService.put('v1/auth/profile', data).then((response) => {
            if (response.success) {
                dispatch({
                    type: GET_USER,
                    payload: response.data
                })
            }
            return response;
        }
        );
}

