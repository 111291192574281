import React from "react";

import ChangePasswordForm from './ChangePasswordForm';
import AuthWrapper from '../AuthWrapper/AuthWrapper';


const ChangePassword = (props) => {
    return (
        <AuthWrapper>
            <div>
                <h2>Change Password</h2>
                <p>You can change your password & access your Raffle On.</p>
                
                <ChangePasswordForm />
            </div>
        </AuthWrapper>
    )
}

export default ChangePassword;