import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from "react-redux";
import CardSection from './CardSection';
// import * as AppActions from "../../../../../store/actions";
import {Spinner} from "react-bootstrap";

export default function CheckoutForm(props) {
    // console.log('props', props)
    const dispatch = useDispatch();

    const stripe = useStripe();
    const elements = useElements();
    // const loggedInUser = useSelector(({ core }) => core.appStore.loggedInUser);

    

    // const stripeClientSecret = useSelector(({ WalletAppStore }) => WalletAppStore.wallet.stripeClientSecret);
    const [errorToDisplay, setErrorToDisplay] = useState('');
    const [processing, setProcessing] = useState(false);
    const handleSubmit = async (event) => {
        setErrorToDisplay('');

        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setProcessing(true)
        // console.log(elements.getElement(CardElement))
        const result = await stripe.confirmCardPayment(props.paymentSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            }
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            //console.log(result.error.message);
            setErrorToDisplay(result.error.message)
            setProcessing(false)
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                props.handleSuccessPayment();
                setProcessing(false)
                // setTimeout(function () {
                //     window.location.reload();
                // }, 6000)
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="mt-5">
            <CardSection />
            <div className="errorMessage">
                {errorToDisplay ? errorToDisplay : null}
            </div>
            {/* <button className="addMoneyBtn" disabled={!stripe}>Confirm order</button> */}

            <div>
                {!processing ? (
                    <button className="btn btn-primary btn-block" disabled={!stripe}>Add</button>
                ) : (
                    <button
                        className="btn btn-primary btn-block"
                        disabled
                    >
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Processing...
                    </button>
                )}
            </div>
        </form>
    );
}
