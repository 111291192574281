import React from 'react';
import { withRouter } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import styles from './PriceBox.module.scss';


const PriceBox = props => {

    const priceContent = [
        "Personalised Onboarding service provided",
        "Cutomised Club Profile Page setup",
        "Club bank account connected",
        "5.5% on all raffle ticket purchases",
        "Deducted from the ticket amount with proceeds sent to club bank account"
    ];
    
    return (
        <section id="pricing">
            <h2 className={styles.sectionHeading}> A simple, low-cost entry point for clubs to get started</h2>
            <Row className="py-5">
                <Col>
                    <Card className="border-0">
                        <Card.Body>
                            <Row className="align-item-center">
                                <Col md={4} className="m-auto">
                                    <div className={styles.priceContent}>
                                        <h3 className={styles.price}>$199</h3>
                                        <h4 className={styles.title}>One Fee, No More to Pay – full setup</h4>

                                        <ul>
                                            {priceContent.map((item, index) => {
                                                return <li className={`${styles.text} d-flex`} key={index}>
                                                    <i className="bi bi-check mr-3"></i>
                                                    {item}
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="py-5">
                <Col>
                    <Card className="border-0">
                        <Card.Body>
                            <h4 className={styles.readyText}>Ready to create a raffle with maximum impact?</h4>
                            <div className={styles.buttonBox}>
                                <Button variant="dark" onClick={() => props.history.push("/draws")}>Buy Your Raffle Ticket Now</Button>
                                <Button variant="outline-dark" onClick={() => props.history.push("/faq")}>Learn more from our FAQ’s</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    );
};


export default withRouter(PriceBox);