import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BreadCrumbs from "../../components/Breadcrumb/Breadcrumb";

import styles from "./Faq.module.scss";

import FaqImage from "../../assets/images/faq.png";

const Faq = (props) => {
  const FaqContent = [
    {
      title: "Can my page be customized to suit our club ?",
      desc: "All dedicated pages are branded using your branding style and unique branded URL.",
    },
    {
      title: "How do you store credit card details ? Are they secure ?",
      desc: "All credit card details and transactions are conducted in a secure environment online. RaffleOn do not share or keep any credit card information with any other parties, other than what is required to complete the transaction (i.e. send to bank) for the purchase of ticket/s. In fact, only our third party payment provider (Stripe) handle any of the information.",
    },
    {
      title: "How do customers receive their tickets ?",
      desc: "Customers will have their raffle tickets delivered straight into their inbox within seconds of completing the streamlined credit card purchasing process with minimal clicks to purchase.",
    },
  ];
  return (
    <div className={`${styles.wrapper}`}>
      <Header showMenu={true} />
      <section className={styles.section}>
        <Container fluid>
          <div>
            <Card className="h-100">
              <BreadCrumbs />
              <Card.Body>
                <div className={styles.cardHeader}>
                  <Row>
                    <Col md={12}>
                      <h2 className={styles.heading}>FAQs </h2>
                    </Col>
                  </Row>
                </div>
                <Row>
                <Col md={6}>
                    {FaqContent.map((item, index) => {
                      return (
                        <>
                          <h5 className={styles.head}>{item.title}</h5>
                          <p className={styles.description}>{item.desc}</p>
                        </>
                      );
                    })}
                  </Col>
                  <Col md={6}>
                    <div className={styles.aboutImage}>
                      <img src={FaqImage} className="img-fluid" alt="Faq img" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default Faq;
