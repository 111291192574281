import React, { useEffect, useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  FormControl,
  Dropdown,
  Nav,
  Navbar,
  Container,
  ListGroup,
} from "react-bootstrap";

import logoWithoutText from "../../assets/images/logo-without-text.svg";
import userDemo from "../../assets/images/user-demo.jpg";
import close from "../../assets/images/close.svg";
import * as Actions from "../../store/actions/index";

import styles from "./Header.module.scss";
import { Action } from "history";

const Header = (props) => {
  const history = useHistory();
  const { showMenu } = props;
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const loggedIn = useSelector(({ core }) => {
    return core.AppReducer.isUserLoggedIn;
  });

  const orgList = useSelector(({ core }) => {
    return core.AppReducer.orgList;
  });
  useEffect(() => {
    console.log("loggedIn", loggedIn);
  }, [loggedIn]);

  useEffect(() => {
    let userDate = JSON.parse(window.localStorage.getItem("login_user"));
    setUser(userDate);
  }, []);
  const handleLogout = () => {
    // dispatch(Actions.logout(false));
    dispatch({
      type: Actions.IS_LOGEDIN_USER,
      payload: false,
    });
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("is_login");
    window.localStorage.clear();
    props.history.push("/");
  };

  // useEffect(() => {
  //     dispatch(Actions.organizationSearch(1, 10, searchText));
  // }, [searchText]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setShowSearchResult(value == "" ? false : true);
    setSearchText(value);
    dispatch(Actions.organizationSearch(1, 10, searchText));
  };

  const goToOrg = (slug) => {
    setShowSearchResult(false);
    setSearchText("");
    history.push(`/club/${slug}`);
  };

  return (
    <Navbar collapseOnSelect expand="lg" className={styles.headerWrapper}>
      <Container fluid>
        <Navbar.Brand
          href="/"
          className={showSearchResult ? styles.overlayBrand : ""}
        >
          <img src={logoWithoutText} alt="logo" className={styles.logo} />
          <span className={styles.brandName}>Raffle On</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto align-items-center">
            <Nav.Item className="nav-link">
              <div
                className={`position-relative ${
                  showSearchResult ? styles.showSearch : ""
                }`}
              >
                <FormControl
                  value={searchText}
                  className={styles.searchBar}
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                />
                {/* <img src={search} className={styles.searchIcon}/> */}
                <SearchIcon
                  className={styles.searchIcon}
                  fill={showSearchResult ? "#062746" : "#fff"}
                />
                {showSearchResult && (
                  <img
                    src={close}
                    alt="close"
                    className={styles.closeIcon}
                    onClick={() => {
                      setShowSearchResult(false);
                      setSearchText("");
                    }}
                  />
                )}
                {showSearchResult && (
                  <>
                    {orgList && (
                      <ListGroup className={styles.searchContent}>
                        {orgList.data.map((org, index) => {
                          return (
                            <ListGroup.Item
                              key={index}
                              className={styles.groupItem}
                              onClick={() => goToOrg(org.url)}
                            >
                              {org.organisationName}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    )}
                  </>
                )}
              </div>
            </Nav.Item>
            {!loggedIn && (
              <Nav.Item className={`nav-link link-border ${styles.loginLink}`}>
                <a
                  className="login-link"
                  href="https://admin.raffleon.club/auth/login"
                >
                  Login
                </a>
                /
                <a
                  className="login-link"
                  href="https://admin.raffleon.club/auth/registration"
                >
                  Signup
                </a>
              </Nav.Item>
            )}
            {loggedIn && (
              <Dropdown className={styles.dropdown}>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                >
                  <div className={styles.userDropdown}>
                    <span className={styles.name}>
                      {user ? `${user.firstName} ${user.lastName}` : ""}
                    </span>

                    <img src={userDemo} alt="user image" />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" alignRight="true">
                  <Dropdown.Item eventKey="1" as={Link} to="/profile">
                    <i className="bi bi-person mr-3"></i> My Profile
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2" as={Link} to="/draws-history">
                    <i className="bi bi-layout-text-sidebar-reverse mr-3"></i>
                    Draw History
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3" as={Link} to="/changepassword">
                    <i className="bi bi-lock mr-3"></i>
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="4" onClick={() => handleLogout()}>
                    <i className="bi bi-box-arrow-right mr-3"></i> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
          {showMenu && (
            <Nav className="ml-auto align-items-center">
              <Nav.Link className={styles.navLink} as={Link} to="/about">
                {" "}
                About
              </Nav.Link>
              <Nav.Link className={styles.navLink} as={Link} to="/faq">
                {" "}
                FAQ
              </Nav.Link>
              <Nav.Link
                className={styles.navLink}
                href="/landing-page#raffelmanagement"
              >
                {" "}
                Raffles
              </Nav.Link>
              <Nav.Link
                className={styles.navLink}
                href="/landing-page#fundraising"
              >
                {" "}
                Fundraising
              </Nav.Link>
              <Nav.Link className={styles.navLink} href="/landing-page#pricing">
                {" "}
                Pricing
              </Nav.Link>
              <Nav.Link
                className={`${styles.navLink} ${styles.bookNow}nav-link  btn-sm btn-dark ml-3`}
                as={Link}
                to="/draws"
              >
                Buy a Ticket now
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default withRouter(Header);

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill={props.fill}
    className={props.className}
  >
    <path
      className="a"
      d="M12.781,10.816,10.828,8.81a5.224,5.224,0,0,0,1.308-3.448A5.479,5.479,0,0,0,6.56,0,5.479,5.479,0,0,0,.984,5.363,5.479,5.479,0,0,0,6.56,10.725a5.669,5.669,0,0,0,3.195-.97l1.979,2.03a.748.748,0,0,0,1.028.019A.682.682,0,0,0,12.781,10.816ZM6.56,1.4a4.049,4.049,0,0,1,4.121,3.964A4.049,4.049,0,0,1,6.56,9.327,4.049,4.049,0,0,1,2.439,5.363,4.049,4.049,0,0,1,6.56,1.4Z"
      transform="translate(-0.984)"
    />
  </svg>
);
