import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';

import styles from './Banner.module.scss';

const Banner = (props) => {
    return <section className={styles.section}>
        <h1>Simple to use online raffles</h1>
        <p>
        Improve your fundraising efforts with our online raffle platform.
         Covid-safe process, easy to set up, automated systems, easy to take sales. Funds directly into your account.
        </p>
        <Row>
            <Col xs={12}>
                <p className={styles.touchText}>Want to get in touch with us?</p>
            </Col>
            <Col xs={12}>
                <Row>
                    <Col >
                        <div className={styles.watchInput}>
                            <div>
                                <Button varient="primary" className={styles.watchButton}> 
                                    <i className="bi bi-play-btn mr-1"></i> Watch Demo
                                </Button>
                            </div>
                            <div>
                                <div className={styles.inputBox}>
                                    <FormControl 
                                        type="email"
                                        placeholder="mailid@gmail.com"
                                    />
                                    <Button varient="primary" className="rounded-0"><i className="bi bi-chevron-right"></i></Button>
                                </div> 
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </section>
}

export default Banner;