import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BreadCrumbs from '../../components/Breadcrumb/Breadcrumb';
import PaginationBox from './components/Pagination/Pagination';
import Filter from './components/Filter';

import DrawCard from './components/DrawCard';
import withReducer from "../../store/withReducer";
import reducer from "./store/reducers";
import Stripe from '../Auth/Stripe';
import * as Actions from './store/actions';
import {getDrawDetails } from './store/actions';
// import light from '../../assets/images/draws/light.jpg';
// import music from '../../assets/images/draws/music.jpg';
// import table from '../../assets/images/draws/table.jpg';
// import treater from '../../assets/images/draws/threater.jpg';
import logoWithoutText from "../../assets/images/logo-without-text.svg";
import logo from '../../assets/images/logo.svg';
import close from '../../assets/images/close.svg';
import dollarCurrency from "../../assets/images/dollar-currency.svg";
import modalSuccess from '../../assets/images/modal-success.svg';

import styles from './Draws.module.scss';
import moment from 'moment';

const filterCheckbox = [{
    label: "All",
    value: 'all'
}, {
    label: "Upcoming",
    value: "upcoming"
}, {
    label: "This month",
    value: "current"
}];

const Draws = (props) => {
    const params = useParams();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [draws, setDraws] = useState([]);
    const [drawType, setDrawType] = useState('all');
    const [searchText, setSearchText] = useState('');
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(12);

    const [paymentIntent, setPaymentIntent] = useState(null);
    useEffect(() => {
        if(params.id) {
            getDrawDetails(params.id).then(res => {
                if(res.codes == 200) {
                    setSelectedCard(res.data);
                    setShow(true);
                }
            });
        }
    }, [params.id]);

    const handleClose = () => {
        setSelectedCard(null);
        setShow(false);
        history.push('/draws');
    };
    const handleShow = (card) => {
        setShow(true);
        setSelectedCard(card);
    };

    const handleBookNow = (id) => {
        dispatch(Actions.getPaymentIntent(id)).then(res => {
            if(res.codes === 200) {
                setPaymentIntent(res.data)
                setShow(false)
                setShowPayment(true);
            }
        });
        
    }
    const [showSuccess, setShowSuccess] = useState(false);

    const handleCloseSuccess = () => {
        setShowSuccess(false);
        history.push('/draws')
    };
    const handleShowSuccess = () => {setShowSuccess(true)
        history.push('/draws');
    };

    const [showPayment, setShowPayment] = useState(false);

    const handlePaymentClose = () => {
        setShowPayment(false)
        history.push('/draws');
    };
    const handlePaymentShow = () => setShowPayment(true);

    const dispatch = useDispatch();

    const drawsData = useSelector(
        ({DrawsStore} ) => {
            return DrawsStore.draws.draws;
        }
    );
    const totalRecord = useSelector(
        ({DrawsStore} ) => {
            return DrawsStore.draws.totalRecord;
        }
    );
    useEffect(() => {
        setDraws(drawsData);
    }, [drawsData]);

    useEffect(() => {
        setLoader(true);
        dispatch(Actions.getDrawList(page, limit,drawType,searchText)).then(res => {
            setLoader(false);
        })   
    },[dispatch])

    useEffect(() => {
        setLoader(true);
        dispatch(Actions.getDrawList(page, limit,drawType,searchText)).then(res => {
            setLoader(false);
        })   
    },[drawType,page,searchText])

    const getDateInFormat = (date, format) => {
        return moment(date).format(format);
    }

    const handleFilterChange = (name,type) => {
        setPage(1);
        if(drawType == name) {
            setDrawType('')
        }else
            setDrawType(name);
    }

    const handleSearch = (text) => {
        setSearchText(text);
    }
    
    const onPageChanged = data => {
        const { currentPage, totalPages, pageLimit } = data;
        setPage(currentPage);
    }

    const handleSuccessPayment = () => {
        handlePaymentClose();
        setShowSuccess(true);
        // history.push('/draws');
    }
    
    return <div className={`${styles.wrapper}`}>
        <Header showMenu={false}/>
        <section className={styles.section}>
            <Container fluid>
            <div>
                <Card>
                    <BreadCrumbs {...props}/>
                        <Card.Body className={styles.cardBody}>
                        <div className={styles.cardHeader}>
                            <Row>
                                <Col md={4}>
                                    <h2 className={styles.heading}>Draws</h2>
                                </Col>
                                <Col md={8}>
                                    <Filter filterCheckbox={ filterCheckbox} totalRecord={totalRecord} drawType={drawType} onChange={handleFilterChange} onSearch={handleSearch}/>
                                </Col>
                            </Row>
                        </div>
                        {loader ? 
                            <Row>
                                <Col>
                                    <div className="loader">
                                        <div className="spinner-border text-warning" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        :
                            <>
                                <Row>
                                    {draws != null  && draws.map((card, index) => {
                                        return (
                                            <Col lg={3} md={4}>
                                                <DrawCard key={index} data={card} handleShow={() => handleShow(card)} handleClose={handleClose}/>
                                            </Col>
                                        )
                                    })}
                                    {draws == null && 
                                        <Col>
                                            <h3 className={styles.noData}>Draws Not Found</h3>
                                        </Col>
                                    }
                                </Row>    
                                <Row className="my-4">
                                    <Col>
                                        <PaginationBox totalRecords={totalRecord} currentPage={page} pageLimit={limit} pageNeighbours={1} onPageChanged={onPageChanged}/>
                                    </Col>
                                </Row>
                                
                            </>
                        }
                    </Card.Body>
                </Card>
            </div>
            </Container>
        </section>
        <Footer/>
        {selectedCard != null && <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
       
        <Modal.Body className={styles.modalBody}>
          <Card>
              <Card.Body className="p-0">
                    
                    <div className={styles.imageBox}>
                        <img src={close} className={styles.closeIcon} onClick={() => handleClose()}/>
                        <img src={logoWithoutText} className={styles.logo}/>
                        <span className={styles.brandName}>Raffle On</span>
                    </div>
                    <div className={styles.content}>
                        <h5 className={styles.drawName}> {selectedCard.drawName}</h5>
                        <p className={styles.description}>
                            {selectedCard.drawDescription}
                        </p>
                        <ul className={styles.details}>
                            <li>
                                <i className="bi bi-calendar4"></i> {getDateInFormat(selectedCard.startDate, "DD MMM YYYY")} TO {getDateInFormat(selectedCard.endDate, "DD MMM YYYY")}
                                {/* DD MMM YYYY */}
                            </li>
                            <li>
                                <Dollor /> {selectedCard.price} {selectedCard.currencyCode}
                            </li>
                        </ul>
                    </div>
                    
                    <Button onClick={() => handleBookNow(selectedCard.id)} variant="secondary" color="dark" className={styles.bookNow}>Book Now</Button>
              </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>}

        <Modal
            show={showSuccess}
            onHide={handleCloseSuccess}
            backdrop="static"
            keyboard={false}
        >
        
            <Modal.Body className={styles.modalBody}>
            <Card className={styles.modalCard}>
                <Card.Body className="p-0">
                        
                        <div>
                            <img src={modalSuccess} className={styles.succesImg}/>
                        </div> 
                        <p className={styles.successText}>Succcess</p>           
                        <Button onClick={handleCloseSuccess} variant="secondary" color="dark" className={styles.bookNow}>Done</Button>
                </Card.Body>
            </Card>
            </Modal.Body>
        </Modal>
            <Modal
            show={showPayment}
            onHide={handlePaymentClose}
            backdrop="static"
            keyboard={false}
        >
        
            <Modal.Body className={styles.modalBody}>
            <Card className={styles.modalCard}>
                <Card.Body className="p-0">
                    <div className={styles.imageBox}>
                        <img src={close} className={styles.closeIcon} onClick={() => handlePaymentClose()}/>
                        <img src={logoWithoutText} className={styles.logo}/>
                        <span className={styles.brandName}>Raffle On</span>
                    </div>
                    <Stripe paymentSecret={paymentIntent} handleSuccessPayment={ handleSuccessPayment }/>
                </Card.Body>
            </Card>
            </Modal.Body>
        </Modal>
    </div>
}

// export default Draws;
export default withReducer("DrawsStore", reducer)(Draws);

const Dollor = () => {
    return <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1"> <g id="surface1">
        <path style={{ " stroke": "none", "fillRule": "nonzero", "fill": "rgb(249 207 123)", "fillOpacity": "1" }} d="M 12.371094 10.964844 C 12.378906 9.699219 11.878906 8.699219 10.882812 7.992188 C 10.113281 7.449219 9.175781 7.164062 8.269531 6.890625 C 6.386719 6.320312 5.839844 6.019531 5.839844 5.117188 C 5.839844 4.113281 7.144531 3.757812 8.261719 3.757812 C 9.074219 3.757812 10.011719 4.007812 10.59375 4.382812 L 11.746094 2.597656 C 10.984375 2.105469 9.945312 1.769531 8.917969 1.667969 L 8.917969 0 L 6.792969 0 L 6.792969 1.785156 C 4.902344 2.199219 3.710938 3.433594 3.710938 5.117188 C 3.710938 6.296875 4.203125 7.230469 5.164062 7.890625 C 5.898438 8.394531 6.792969 8.664062 7.65625 8.925781 C 9.503906 9.484375 10.253906 9.824219 10.246094 10.953125 L 10.246094 10.960938 C 10.246094 11.90625 8.992188 12.242188 7.917969 12.242188 C 6.902344 12.242188 5.796875 11.800781 5.171875 11.136719 L 3.628906 12.597656 C 4.421875 13.4375 5.570312 14.027344 6.792969 14.261719 L 6.792969 16 L 8.917969 16 L 8.917969 14.300781 C 11.019531 14 12.371094 12.734375 12.371094 10.964844 Z M 12.371094 10.964844 " />
    </g>
    </svg>
}