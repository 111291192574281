import axios from 'axios';
import history from '../history';
import store from '../store';
const BASE_API_URL = "https://api.raffleon.club/api/"
// const BASE_API_URL = "https://api.gamerzarena.com/api/"
// const BASE_API_URL = "https://console.gamerzarena.com/api/"
// const BASE_API_URL = "https://testingconsole.gamerzarena.com/api/"
// const BASE_API_URL = "http://gamerzarena-app.local/api/"


class webApiService {
    constructor() {
        console.log('interceptor init')
        this.setInterceptors();
    }

    returnBaseUrl() {
        return BASE_API_URL;
    }

    setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
                
                if(response.data.codes == 401){
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('is_login');
                    window.localStorage.clear();
                    window.location.href = "/login"
                    return response
                }else
	    			return response;
			},
			err => {
				return new Promise((resolve, reject) => {
                    
					if (err.codes === 401) {
                        window.location.href = "/login"
						// if you ever get an unauthorized response, logout the user
						// this.emit('onAutoLogout', 'Invalid access_token');
						// this.setSession(null);
                        // window.localStorage.setItem('is_login', false);
					}
					throw err;
				});
			}
		);
	};

    logout = (id) => {
        axios.defaults.headers.common['Authorization'] = this.getAccessToken();
        return new Promise((resolve, reject) => {
            axios.post(BASE_API_URL + 'user/logout', { user_id: id }).then(response => {
                if (response.status === 200) {
                    this.clearLocalStorage()
                    resolve(response)
                } else {
                    resolve(response.message)
                }
            });
        });
    };
    setLoginUser = (data) => {
        window.localStorage.setItem('login_User', JSON.stringify(data));
    };
    getAccessToken = () => {
        return 'Bearer ' + window.localStorage.getItem('token');
    };
    clearLocalStorage = () => {
        localStorage.clear();
    };
    getLoginUser = () => {
        let user = window.localStorage.getItem('login_User');
        user = JSON.parse(user);
        return user
    };
    getLoginUserId = () => {
        let user = window.localStorage.getItem('login_User');
        user = JSON.parse(user);
        return user !== undefined && user !== null && user.id !== undefined ? user.id : null;
    };
    get = (route) => {
        axios.defaults.headers.common['Authorization'] = this.getAccessToken();

        return new Promise((resolve, reject) => {
            axios.get(BASE_API_URL + route).then(response => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(response.message);
                }
            });
        });
    };
    post = (route, data) => {
        let token = (this.getAccessToken() !== null) ? this.getAccessToken() : null;

        axios.defaults.headers.common['Authorization'] = token;
        return new Promise((resolve, reject) => {
            axios.post(BASE_API_URL + route, data).then(response => {
                if (response.status === 200) {
                    // window.localStorage.removeItem('temp_access_token');
                    // window.localStorage.removeItem('twitch_user_id');
                    resolve(response.data);
                } else {
                    reject(response.message)
                }
            });
        });
    };
    put = (route, data) => {
        let token = (this.getAccessToken() !== null) ? this.getAccessToken() : null;

        axios.defaults.headers.common['Authorization'] = token;
        return new Promise((resolve, reject) => {
            axios.put(BASE_API_URL + route, data).then(response => {
                if (response.status === 200) {
                    // window.localStorage.removeItem('temp_access_token');
                    // window.localStorage.removeItem('twitch_user_id');
                    resolve(response.data);
                } else {
                    reject(response.message)
                }
            });
        });
    };
}

const instance = new webApiService();

export default instance;
