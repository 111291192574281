import React from 'react';
import Form from 'react-bootstrap/Form';

import search from '../../../assets/images/search_grey.svg';

import styles from './Filter.module.scss';

const Filter = props => {
    const handleChange = (event) => {
        const { name, value, checked } = event.target;
        props.onChange(value, checked);
    }

    const handleSearch = (event) => {
        const { value } = event.target;
        props.onSearch(value);
    }
    return (
        <div className={styles.wrapper}>
            <Form.Group className={styles.filterBox}>
                {props.filterCheckbox.map((item, index) => {
                    return <Form.Check key={index} value={item.value} type="checkbox" checked={props.drawType == item.value}
                        label={props.drawType == item.value ? item.label+`(${props.totalRecord?props.totalRecord:0})` : item.label}
                        onChange={handleChange} />
                })}

                {/* <Form.Check value="all" type="checkbox" checked= {props.drawType == "all"}label="All" onChange={handleChange}/>
                <Form.Check value="upcoming" type="checkbox" label="Upcoming" checked= {props.drawType == "upcoming"} onChange={handleChange}/>
                <Form.Check value="current" type="checkbox" label="This month" checked= {props.drawType == "current"} onChange={handleChange}/> */}
                <div className="position-relative">
                    <Form.Control
                        type="text"
                        className={styles.search}
                        placeholder="Find Draws"
                        onChange={handleSearch}
                    />
                    <img src={search} className={styles.searchIcon} />
                </div>
            </Form.Group>
        </div>
    );
};

Filter.propTypes = {

};

export default Filter;