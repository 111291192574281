import * as Actions from '../actions';
const initialState = {
    draws: null,
    totalRecord: 0,
    totalHistoryRecord: 0,
    historyList: null
};

const Reducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_DRAWS_LIST:
            {
                return {
                    ...state,
                    draws: action.payload.data,
                    totalRecord: action.payload.totalRecord
                };
            }
        case Actions.GET_DRAW_HISTORY_LIST:
            {
                return {
                    ...state,
                    historyList: action.payload.data,
                    totalHistoryRecord: action.payload.totalRecord
                };
            }
        default:
            {
                return state;
            }
    }
};

export default Reducer;
