import React from 'react';
import {Navbar, Nav,Container} from 'react-bootstrap';
import logoWithoutText from "../../assets/images/logo-without-text.svg";
import styles from './Footer.module.scss';

const Footer = props => {
    return (
        <Navbar bg="transparent" expand="lg">
            <Container fluid>
                <Navbar.Brand>
                    <img className={styles.logo} src={logoWithoutText} alt="logo"/>
                    <span className={styles.brandName}>Raffle On</span>
                </Navbar.Brand>
                <Nav className="ml-auto">
                    <Nav.Link className={styles.navLink} href="#home">Support</Nav.Link>
                    <Nav.Link className={styles.navLink} href="#link">Privacy Policy</Nav.Link>
                    <Nav.Link className={styles.navLink} href="#link">Term of use</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default Footer;