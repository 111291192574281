import {combineReducers} from 'redux';
import message from './message.reducer';
import AppReducer from './app.reducer';

const fuseReducers = combineReducers({
    AppReducer,
    message
});

export default fuseReducers;
