/* eslint-disable */

import * as Actions from '../../actions/core/index';
import { getLoginUserInfo, getIsAdmin } from '../../../utils/getLoginData'

console.log(getLoginUserInfo())
const initialState = {
    isUserLoggedIn: getLoginUserInfo() != null ? true : false,
    success: false,
    error: {
        username: null,
        password: null
    },
    orgList: null
};

const AppReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.IS_LOGEDIN_USER:
            {
                return {
                    ...state,
                    isUserLoggedIn: action.payload
                };
            }
       
        // case Actions.LOGIN_SUCCESS:
        //     {
        //         return {
        //             ...initialState,
        //             isUserLoggedIn: true
        //         };
        //     }
        case Actions.LOGIN_ERROR:
            {
                return {
                    isUserLoggedIn: false,
                    error: action.payload
                };
            }
        case Actions.USER_LOGOUT:
            {
                return {
                    ...initialState,
                    isUserLoggedIn: false
                };
            }
        case Actions.ORG_LIST:
            {
                return {
                    ...initialState,
                    orgList: action.payload
                };
            }
        default:
            {
                return state;
            }
    }
};

export default AppReducer;
