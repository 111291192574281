import React from 'react';
import Card from 'react-bootstrap/Card';
import {useHistory, useParams, Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import styles from './DrawCard.module.scss';
import moment from 'moment';

const DrawCard = (props) => {
    const history = useHistory();
    let card = props.data;
    const {handleShow, slug} = props;

    const getDateInFormat = (date, format) => {
        return moment(date).format(format);
    }

    const handleBook = () => {
        history.push(`/club/${slug}/${card.id}`);
    }
    return (<div className={styles.wrapper} key={props.key}>
        <Card className={styles.card}>
            <Card.Img variant="top" src={card.drawImage} className={styles.cardImg}/>
            <Card.Body className={styles.cardBody}>
                <p className={styles.name}>{card.drawName}</p>
                {card.organisationName ? <Link to={`/club/${card.organisationSlug}`} className={styles.orgName}>{card.organisationName}</Link> : ''}
                <Card.Text className={styles.date}>
                    Date {getDateInFormat(card.startDate, "DD MMM YYYY")} to  {getDateInFormat(card.endDate, "DD MMM YYYY")}
                </Card.Text>

                <div className={"d-flex justify-content-between align-items-center"}>
                    <h3 className={styles.price}>${card.price} each</h3>
                    <Button onClick={handleBook} variant="dark" color="primary" size="sm" className="px-1" className={styles.bookNow}>Book Now</Button>
                </div>
            </Card.Body>
        </Card>
    </div>)
                                    
}

export default withRouter(DrawCard);